// ==================================================
//  変数定義
// ==================================================
const header = document.querySelector("header");
let scrollTicking = false;

// ==================================================
//  Event: スクロールイベント
// ==================================================
document.addEventListener(
  "scroll",
  (event) => {
    if (!scrollTicking) {
      requestAnimationFrame(() => {
        scrollTicking = false;

        // 追従ヘッダーの横スクロール対応
        header.style.left = -window.scrollX + "px";
      });
      scrollTicking = true;
    }
  },
  { passive: true }
);
